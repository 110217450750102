
  import { Component, Prop } from 'vue-property-decorator'
  import { Field } from './field'
  import { getObjectValueByPath } from '@/utils/vuetify/helpers'

@Component
  export default class HiddenSelector extends Field {
  @Prop({ type: Array, default: () => [] }) readonly items!: Array<any>
  @Prop({ type: String }) readonly itemValue!: string
  @Prop({ type: [Number, String] }) readonly selected!: any

  get val () {
    const { selected } = this
    if (!selected) return

    const { value, itemValue } = this
    const val = itemValue ? getObjectValueByPath(value, itemValue) : value

    if (selected !== val) {
      const _ = this.items.find(item => getObjectValueByPath(item, itemValue) === selected)
      this.set(_)
      return selected
    }

    return val
  }
  }
